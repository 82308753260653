
.header-session {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;


  &--menu-actions {
    position: absolute;
    right: 0;
    width: 30px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .w_menu--icon {
      width: 26px;
      height: auto;
      color: #5b5b5b;
      cursor: pointer;
    }
  }
}

.menu-button {
  width: 70%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.system-name--label {
  font-size: 0.95rem;
}
.select {
  width: 100%;
}
.session-select {
  position: relative;
}
.container-timezone {
  margin: 0;
  height: 80px;
}
.container-select {
  // margin: 20px 16px;
  margin: 0 16px;
  height: 80px;

  .MuiSelect-select {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .device--select-alias {
    margin-left: 17px;
    font-size: 0.7rem;
    font-weight: 500;
    position: relative;
    top: 2px;
    color: #646464;
  }

  #add--sensor-dp {
    svg {
      margin-right: 20px;
    }
    svg.awair-icon {
      width: 25px;
      height: auto;
    }
  }

  .warn-alert {
    &--wrapper {
      color: red;
      width: 100%;
      display: flex;
    }

    &--icon {
      margin-left: 30px;

      .w_a--icon {
        color: red;
        width: 16px;
        height: auto;
        animation: blink-icon 1s ease-in-out infinite;
      }

      @keyframes blink-icon {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.3);
        }
        100% {
          transform: scale(1);
        }
      }
    }
  }

  .device-zone--select.sensor--warn {
    border-bottom: 1.5px solid red;
    animation: blinking 1s ease-in-out infinite;

    .MuiInput-underline:before {
      border-bottom: 0.1px solid red;
    }
  }

  @keyframes blinking {
    0% {
      border-bottom: 1.5px solid red;
    }

    50% {
      border-bottom: 0;
    }

    100% {
      border-bottom: 1.5px solid red;
    }
  }
}

.container-input {
  // margin: 20px 0;
  margin: 0 16px;
  height: 80px;
}
.inputLabel {
  font-size: 0.95rem !important;
}
.container-hours {
  display: flex;
  margin: 12px 0 0;

  p {
    font-weight: bold;
    color: rgb(107, 104, 104);
  }
}
.container-button {
  margin: 35px 16px 0;
  display: flex;
  justify-content: flex-end;

  button.btn-primary {
    box-shadow: 4px 4px 14px -1px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 4px 4px 14px -1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 4px 4px 14px -1px rgba(0, 0, 0, 0.4);
  }
}
label {
  flex: 1;
  font-size: 1.2rem;
  font-weight: 600;
  opacity: 0.7;
  margin-bottom: 5px;
}
input[type='radio'] {
  display: none;
}
.hours-operation {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 0.85rem;
  margin: 0;

  div {
    button {
      margin-left: 10px;
    }
  }
}

.edit-location-wrapper {
  margin-top: calc(33.5px + 2rem);
}
.no-p {
  padding-left: 0;
  padding-right: 0;
}

.warn-section {
  width: 100%;
  padding: 16px 16px 0;

  .ss-warn-msm {
    &--title {
      width: 100%;
      font-weight: bold;
    }
    &--wrapper {
      width: 100%;
      min-height: 50px;
      background-color: #ff6e003d;
      border: 1px solid gray;
      border-radius: 6px;
      font-size: 12px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      box-shadow: 4px 4px 14px -1px rgba(0, 0, 0, 0.4);
      -webkit-box-shadow: 4px 4px 14px -1px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 4px 4px 14px -1px rgba(0, 0, 0, 0.4);
    }
  }
}

#edit-device {
  .section-1,
  .section-2,
  .section-3,
  .section-4 {
    margin-bottom: 20px;
    width: 100%;
  }

  .section-5 {
    margin-bottom: 40px;
  }

  .section-4 {
    display: flex;
    flex-flow: row;

    .form-group {
      max-width: 100%;
    }
  }

  .section-1,
  .section-2,
  .section-3,
  .section-4,
  .section-5 {
    width: 100%;

    .form-group {
      label {
        font-weight: 500;
      }
    }

    .zone-img-wrapper,
    .status-wrapper {
      .form-group {
        display: flex;
        flex-direction: column;
        padding: 0 15px;

        .device-zone {
          &--preview {
            min-height: 200px;
            border: 1px solid #dfdfdf;
            border-radius: 8px;
            margin-bottom: 15px;
            overflow: hidden;

            img.preview-container {
              width: 100%;
              height: 30vh;
              border-radius: 8px;
            }
          }

          &--select {
            width: 100%;

            div.MuiInputBase-root {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.divider {
  padding: 33.5px 16px;

  hr {
    border: none;
    color: transparent;
  }
}

.add--sensor-dp-option {
  svg {
    margin-right: 20px;
  }
  svg.awair-icon {
    width: 25px;
    height: auto;
  }
}

#device-settings {
  .inputLabel {
    margin-top: 4px;
  }

  .calc-mode--tz {
    margin-top: 8px;
  }

  .form-group {
    margin-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}



#sysSaveConfigModal {



  .btn-default {
    background-color: rgb(220, 220, 220);
    border: 1px solid #9c9c9c;
    margin-right: 25px;
  }
}



#applyConfiguration {

  .modal-title {
    width: 100%;
    text-align: center;
  }

  .text-msm-row,
  .icon-row {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .form-wrapper {
    width: 70%;
    margin: 0 auto;

    .form-title {
      width: 100%;
    }


    .select-config-row {
      margin: 5% 0 0 0;

      *.row {
        margin: 0;
      }
    }

    .select-target-row {
      margin: 8% 0;

      *.row {
        margin: 0;
      }
    }

  }


  .modal-footer {
    
    .btn-primary {
      width: 120px;
      margin-left: 5%;
    }
    .btn-default {
      width: 120px;
    }
  }

}
